import React, { useEffect, useRef, useState, useContext } from "react"
import PropTypes from "prop-types"
import {colors} from "./colors"
import GlobalStyle from "../globalStyles"
import CookieConsent from "react-cookie-consent";
import { ThemeProvider } from "styled-components"
import { lightTheme, darkTheme } from "./Theme/Theme"
import Header from "./header"
import Footer from "./footer"
import Link from "gatsby-link"

import { GlobalContext } from "./GlobalProvider"


const Layout = ({ children, path }) => {
  const context = useContext(GlobalContext)
  console.log(context)
  const [sticky , setSticky ] = useState(false)
  // const [isDark, setIsDark] = useState("light")
  const headerRef = useRef(null)
  let lastScrollTop = 0;
  const handleScroll = (e) => {
    let st = e.target.scrollTop || document.body.scrollTop;
    if (st > lastScrollTop ){
      // downScroll code
      setSticky(headerRef.current.getBoundingClientRect().top <= 0 )
    } else  {
      // upScroll code
      if(st === 0) {
        setSticky(false)
      }
    }
    lastScrollTop = st <= 0 ? 0 : st;
    // setSticky(headerRef.current.getBoundingClientRect().top <= 0 )

  }
  useEffect(() => {
    document.querySelector("#gatsby-focus-wrapper").addEventListener('scroll', handleScroll);
    return () => {
      document.querySelector("#gatsby-focus-wrapper").removeEventListener('scroll', handleScroll);
    }
  })
  return (
    <ThemeProvider theme={context.isDark === "dark" ? darkTheme : lightTheme}>
      <GlobalStyle theme={context.isDark} />
      <Header ref={headerRef} setTheme={context.setIsDark} isDark={context.isDark} isSticky={sticky} path={path}/>
      <div>
        <main>{children}</main>
      </div>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        buttonStyle={{
          backgroundColor:colors.secondaryGreen,
          borderRadius:"3px",
          minWidth:"75px"
        }}
        contentStyle={{
          flex:"1 0 250px"
        }}
        declineButtonText="Decline"
        flipButtons
        cookieName="gatsby-gdpr-google-analytics">
          <strong>This website uses cookies</strong>
          <p>We use cookies to make your experience on our website better and to analyse our traffic.</p>
          <p>Check our <Link style={{color:colors.primaryGreen, textDecoration:"none"}} to="/privacy-policy">Privacy Policy</Link> for more information</p>
      </CookieConsent>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
