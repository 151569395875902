import {colors} from "../colors"

export const darkTheme = {
  body: "#121212",
  surface: "#1D1D1D",
  font: "#D1D1D1",
}

export const lightTheme = {
  body: "#FFFFFF",
  surface: "#EEF2F5",
  font: colors.primaryGrey,
}