import React from "react"
import StyledMenu from "./style"
import { Link, withPrefix } from "gatsby"
const Menu = ({ open, path }) => {
  return (
    <StyledMenu open={open}>
      {/*<Link className={path === withPrefix('/services') ? "active" : null} to="/services">Services</Link>*/}
      {/*<Link className={path === withPrefix('/promo') ? "active" : null} to="/promo">Promo</Link>*/}
      <Link className={path === withPrefix('/') ? "active" : null} to="/">Home</Link>
      <Link className={path === withPrefix('/contact') ? "active" : null} to="/contact">Contact</Link>
    </StyledMenu>
  )
}
export default Menu
