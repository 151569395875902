import React from "react"
import styled from "styled-components"
import PlugLeft from "../../images/svg/left.svg"
import PlugRight from "../../images/svg/right.svg"

const PlugInOffSwitcher = styled(({ className, isDark, onClick }) => {
  return (
    <button onClick={onClick} className={`${className} ${isDark === "dark" ? "dark" : ""}`}>
      <div className="plug">
        <span className="left">
          <img src={PlugLeft} alt="left plug" />
        </span>
        <span className="text">Charge</span>
        <span className="right">
          <img src={PlugRight} alt="right plug" />
        </span>
      </div>
    </button>
  )
})`
  position: relative;
  width: 70px;
  margin: 16px;
  padding: 0;
  background: none;
  border: none;
  z-index: 100;
  cursor: pointer;
  .plug {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    position: absolute;
    top:5px;
    left:0;
    width:100%;
    text-transform: uppercase;
    font-weight: bold;
  }
  .left {
    position: relative;
    z-index: 100;
    transform: translateX(-37px);
    transition: transform .2s;
  }
  .right {
    position: relative;
    z-index: 100;
    transform: translateX(28px);
    transition: transform .2s;
    img {
      width:30px;
    }
  }
  &.dark {
    .left {
      transform: translateX(5px);
    }
    .right {
      transform: translateX(-5px);
    }
  }
  span {
    display: block;
    height:auto;
    transform: translateX(0);
    img {
      display: block;
      width:100%;
    }
  }
`

export default PlugInOffSwitcher
