import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width:100%;
  max-width:${({fluid}) => fluid ? "initial" : "1200px"};
  margin:auto;
  padding-right:1rem;
  padding-left:1rem;
`

export default Container