import { createGlobalStyle } from 'styled-components';
import {colors} from "./components/colors"

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;500;700&display=swap');
  html, body {
    margin: 0;
    padding: 0;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    height: 100vh;
    text-rendering: optimizeLegibility;
    font-family: Montserrat,serif;
    font-size:calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
    background-color:${props => props.theme === "dark" ? "#121212" : "#ffffff" }
    color:${props => props.theme === "dark" ? colors.thirdGrey : colors.primaryGrey }
  }
  #___gatsby {
    overflow-x:hidden;
    height:100%;
  }
  #gatsby-focus-wrapper {
    position:relative;
    display:flex;
    flex-direction:column;
    height:100%;
    justify-content: space-between;
    overflow-x:hidden;
  }
  p {
    font-weight: 300;
  }
`;

export default GlobalStyle;