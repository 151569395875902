import React, { forwardRef, useState } from "react"
import PropTypes from "prop-types"
import Logo from "../images/svg/logo.svg"
import LogoLink from "./LogoLink"
import Burger from "./BurgerButton"
import Menu from "./Navbar"
import styled from "styled-components"
import Container from "./Container"
import { colors } from "./colors"
import PlugInOffSwitcher from "./PlugInOffSwithcer/PlugInOffSwitcher"

const Header = styled(forwardRef(({ className, isSticky, path, setTheme, isDark }, ref) => {
  const [open, setOpen] = useState(false)
  const darkModeHandler = () => {
    setTheme(isDark === "light" ? "dark" : "light")
  }

  return (
    <header ref={ref} className={className}>
      <Container>
        <div className="wrapper">
          <div className="logo">
            <LogoLink logoImg={Logo} />
          </div>
          <div className="toggler2">
            <PlugInOffSwitcher onClick={darkModeHandler} isDark={isDark} />
          </div>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} path={path} />
        </div>
      </Container>
    </header>
  )
}))`
  position:${({isSticky}) => isSticky ? "sticky" : "relative"};
  top:${({ isSticky }) => isSticky ? "0px" : null };
  //border-bottom:${({isSticky, theme }) => isSticky ? theme.body === "#121212" ? `1px solid #6b6868` : `1px solid ${colors.thirdGrey}`  : "none"};
  box-shadow:${({isSticky, theme}) => isSticky ? theme.body === "#121212" ? `0px 0px 20px 0px #888686` : "0px 0px 20px 0px #eaeaea"  : "none"};
  z-index:20;
  background-color:${props => props.theme ? props.theme.body : "white"};
  .wrapper {
    width:100%;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (min-width: 768px){
      justify-content: space-between;
    }
    flex-wrap:wrap;
    padding-top:1rem;
    padding-bottom:1rem;
    height:80px;
  }
  .toggler, .toggler2 {
    margin-right:calc((100% - 116px) - (50% - 26.5px));
    @media screen and (min-width: 768px) {
      margin-right:0;
    }
    > div {
      width:49px !important;
      height:22px !important;
    }
  }
  .logo {
    order:2;
    @media screen and (min-width: 768px) {
      order:0
    }
    width:30%;
    height:100%;
    max-width:53px;
    position:relative;
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
