import React from "react"
import styled from "styled-components"
import ImpulsImg from "../../images/svg/impuls.svg"
import {colors} from "../colors"

const StyledMenu = styled.nav`
  display: flex;
  z-index: 9;
  flex-direction: column;
  justify-content: center;
  background: #EFFFFA;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  box-shadow: ${({ open }) => open ? '0 0 20px 0 #cac6c6' : 'none'};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  @media screen and (min-width: 768px){
    transform: translateX(0);
    flex-direction: row;
    position:relative;
    justify-content: flex-end;
    width:70%;
    background:none;
    height:auto;
    padding:.3rem;
    max-width:600px;
  }
  @media (max-width: 576px) {
      width: 100%;
    }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.1rem;
    color: ${colors.primaryGreen};
    text-decoration: none;
    transition: all 0.3s linear;
    min-width:150px;
    @media screen and (min-width: 768px){
      margin-left:2rem;
      &:hover {
        //font-weight: bold;
        text-shadow: 0 0 1px ${colors.primaryGreen};
        transform:scale(.95);
        color:${colors.primaryGreen};
      }
    }
  
    //@media (max-width: 576px) {
    //  font-size: 1.5rem;
    //  text-align: center;
    //}
    @media screen and (min-width: 768px) {
      position:relative;
      font-size: 1rem;
      text-align: center;
      font-weight:normal;
      text-transform:initial;
      padding:.3rem 2rem;
      &.active {
        color: ${colors.primaryGreen};
        font-weight: bold;
      }
      &:after {
        content: "";
        position:absolute;
        width:2rem;
        height:100%;
        right:0;
        top:0;
        background:url(${ImpulsImg}) no-repeat center;
        background-size:contain;
      }
    }
    //&:hover {
    //  color: #343078;
    //}
  }
`
export default StyledMenu