import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"


const LogoLink = styled(({ className }) => {
  return (
    <div className={className}>
      <h2><Link to="/"/></h2>
    </div>
  )
})`
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  width:100%;
  h2 {
    margin:0;
    height:100%;
    a {
      display: block;
      height:100%;
    }
  }
  // ${Link} {
  //   text-indent: -9999px;
  // }
  background-image:${props => props.logoImg ? `url(${props.logoImg})` : null};
  background-position:center;
  background-size:contain;
  background-repeat: no-repeat;
`

export default LogoLink