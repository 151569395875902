import React from "react"
import styled from "styled-components"
import Container from "./Container"
import {colors} from "./colors"
import {FaFacebookF, FaInstagram, FaEnvelope, FaPhoneAlt, MdLocationOn} from "react-icons/all"
import Link from "gatsby-link"

const Footer = styled(({ className }) => {
  return (
    <footer className={className}>
      <Container>
        <div className="footer-inner">
          <div className="footer-inner__box footer-inner__box--first">
            <h5><span>KW</span> <span>Hybrid</span> <span>Lifeguard</span></h5>
            <span>By appointment only</span>
          </div>
        <div className="footer-inner__box footer-inner__box--second">
          <span className="title">Visit us</span>
          <span className="with-icon with-icon-location"><div><MdLocationOn size="30" /></div><a target="_blank" style={{color:colors.primaryGrey}} href="https://www.google.com/maps/place/1595+Victoria+St+N,+Kitchener,+ON+N2B+3E6,+Canada/@43.4733307,-80.4375843,17z/data=!3m1!4b1!4m5!3m4!1s0x882b8ca252ed596f:0x55d854683a36b557!8m2!3d43.4733268!4d-80.4353903">1595 Victoria St N, Kitchener, ON N2B3E6</a></span>
        </div>
        <div className="footer-inner__box footer-inner__box--third">
          <span className="title">Contact us</span>
          <span className="with-icon with-icon--email"><div><FaEnvelope size="20" /></div>kwhybridlifeguard@gmail.com</span>
          <span className="with-icon with-icon--phone"><div><FaPhoneAlt size="20" /></div>+1 519-502-3115</span>
        </div>
        <div className="footer-inner__box footer-inner__box--fourth">
          <span className="title">Follow us</span>
          <div className="social-link-icons">
            <a href="https://www.facebook.com/kwhybridlifeguard"><FaFacebookF size="30" color={colors.primaryGreen} /></a>
            <a href="https://www.instagram.com/kwhybridlifeguard/"><FaInstagram size="30" color={colors.primaryGreen} /></a>
          </div>
        </div>
        </div>
      </Container>
      <div className="copyright">
        <Container>
          <div className="powered">
            <a href="https://greenflamingobcn.com">Powered by <span>Green Flamingo</span></a>
          </div>
          <div className="privacy">
            <p>Copyright &copy; 2021 / <Link to="/privacy-policy">Privacy Policy</Link></p>
          </div>
        </Container>
      </div>
    </footer>
  )
})`
  padding:30px 0 0 0;
  background-color: ${({theme}) => theme ? theme.body : "white"};
  box-shadow: ${({theme}) => theme.body === '#121212' ? `0 0 17px 0 ${colors.primaryGrey}` : "0 0 17px 0 #e2e2e2"};
  @media screen and (min-width: 768px){
    padding:50px 0 0 0;
  }
  .footer-inner {
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
    &__box {
      width:100%;
      margin-bottom:2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height:100px;
      @media screen and (min-width: 576px){
        width:48%;
      }
      @media screen and (min-width: 992px) {
        width:24%;
      }
      span {
        display: block;
      }
      .with-icon {
        display:flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom:10px;
        div {
          display: flex;
          align-items: center;
        }
        svg {
          margin-right:5px;
          color:${colors.primaryGreen};
        }
      }
      .title {
        text-transform: uppercase;
        font-weight: bold;
        color: ${({theme}) => theme.body === "#121212" ? theme.font : colors.secondaryGrey};
        margin-bottom:1rem;
      }
      .social-link-icons {
        display: flex;
        @media screen and (min-width: 992px) {
          justify-content: flex-end;
        }
        a {
          margin-left:10px;
        }
      }
      &:last-child {
        .title {
          @media screen and (min-width: 992px) {
            text-align: right;
          }
        }
        justify-content: flex-start;
      }
      &--first {
        h5 {
          font-size: 20px;
          text-transform: uppercase;
          margin-bottom:.5rem;
          margin-top:0;
          span:first-child {
            color:${colors.thirdGreen};
          }
          span:nth-child(2) {
            color:${({theme}) => theme.body === "#121212" ? theme.font : colors.secondaryGrey};
          }
          span:last-child {
            color:${colors.primaryGreen}
          }
        }
      }
    }
  }
  .copyright {
    background-color:${colors.primaryGreen};
    color:white;
    margin-top:50px;
    font-weight:200;
    ${Container} {
      display: flex;
      justify-content: space-between;
      flex-wrap:wrap;
      align-items: center;
    }
  }
  a {
    text-decoration: none;
    color:white;
  }
  .powered {
    margin:1rem 0;
    a {
      span {
        font-weight:bold;
      }
    }
  }
  .privacy {
    margin:1rem 0;
    p {
      margin:0;
    }
    a {
      color: ${colors.secondaryGreen};
    }
  }
`

export default Footer